<template>
  <div class="bg">
    <img
      src="./img/1.png"
      alt=""
      class="nav"
      @click="toRoute"
    >
    <div class="main">
      <div class="left_main">
        <div class="statistics">
          <span>数据统计</span>
          <span class="tip_en">data statistics</span>
        </div>
        <div class="fildNum">
          <div class="num">{{ fildOrdeviceInfo.fieldNum }}</div>
          <div class="tip">场地总数</div>
        </div>
        <div class="statistics_info">
          <div class="device">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">设备总数</div>
              <div class="device_info_num">{{ fildOrdeviceInfo.devNum }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/8.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
          <div class="prot">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">充电端口数</div>
              <div class="device_info_num">{{ fildOrdeviceInfo.portNum }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/9.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>收益统计</span>
          <span class="tip_en">Revenue Statistics</span>
        </div>
        <div class="income">
          <div class="day_income">
            <img
              src="./img/10.png"
              alt=""
              class="day_income_icon"
            >
            <div class="day_income_info">
              <div class="day_income_info_tip">今日收益</div>
              <div class="day_income_num">{{ incomeInfo.dayIn }}</div>
            </div>
          </div>
          <div class="Monthly_income">
            <img
              src="./img/10.png"
              alt=""
              class="day_income_icon"
            >
            <div class="day_income_info">
              <div class="day_income_info_tip">本月收益</div>
              <div class="day_income_num">{{ incomeInfo.monthIn }}</div>
            </div>
          </div>

        </div>
        <!-- 折线图 -->
        <div class="line-chart">
          <!-- <line-chart :chart-data="lineChartData1" /> -->
        </div>
      </div>
      <!-- 地图 -->
      <div class="center_main">
        <!-- <div id="container"></div> -->
        <el-amap
          ref="map"
          :zoom="9"
          :events="events"
          id="container"
        >
          <!-- <el-amap-marker
            v-for="(marker, index) in markers"
            :key="index"
            :vid="index"
            :position="[marker.longitude, marker.latitude]"
            :events="marker.events"
            :icon="marker.icon"
            :title="marker.title"
            :label="marker.label"
          >
          </el-amap-marker> -->
        </el-amap>
      </div>
      <div class="right_main">
        <div class="statistics">
          <span>用户统计</span>
          <span class="tip_en">User Statistics</span>
        </div>
        <div class="fildNum">
          <div class="num">{{ fildOrdeviceInfo.useNum }}</div>
          <div class="tip">用户总数</div>
        </div>
        <div class="statistics_info">
          <div class="device">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">今日新增</div>
              <div class="device_info_num">{{ incomeInfo.dayUserIn }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/8.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
          <div class="prot">
            <div class="line" />
            <div class="device_info">
              <div class="device_info_tip">本月新增</div>
              <div class="device_info_num">{{ incomeInfo.monthUserIn }}
                <span class="device_info_num_unit">个</span>
              </div>
            </div>
            <div class="device_bg">
              <img
                src="./img/9.png"
                alt=""
                class="device_ivon"
              >
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>充电桩分布</span>
          <span class="tip_en">Distribution Of Charging Stations</span>
        </div>
        <div>
          <!-- 饼状图 -->
          <!-- <PancakeChart :chart-data="lineChartData" /> -->
        </div>
        <div class="statistics">
          <span>充电数据</span>
          <span class="tip_en">Charging Data</span>
        </div>
        <div>
          <!-- <pie-chart :chart-data="lineChartData2" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from './components/LineChart'
import PieChart from './components/PieChart'
import PancakeChart from './components/PancakeChart'
import { qryFieldInfo, getFixationData, getChangeData } from './api/home'
import router from '@/router'

import AMapLoader from '@amap/amap-jsapi-loader';

// window._AMapSecurityConfig = {
//   securityJsCode: "6d2e5df6d96f0efebd8f530ef5e78b02",
// }
import Vue from 'vue'
// 高德地图
import VueAMap from 'vue-amap'
import { AMapManager } from 'vue-amap'
const amapManager = new AMapManager()

const lineChartData = {
  goodsSaleInfo: {
    timeData: [],
    totalMoneyData: []
  },
  UserConsume: {
    timeData: [],
    totalMoneyData: []
  },
  TrendInfo: {
    timeData: [],
    orderNumData: [],
    parkOrderNumData: []
  }
}
export default {
  name: 'DashboardAdmin',
  components: {
    LineChart,
    PieChart,
    PancakeChart,
  },

  data () {
    return {
      amapManager,
      lineChartData: lineChartData.goodsSaleInfo,
      lineChartData1: lineChartData.UserConsume,
      lineChartData2: lineChartData.TrendInfo,
      FelidList: [],
      AengtList: [],
      roleNo: '',
      // center: [116.396304, 39.909847],
      events: {
        init: (o) => {
          var opts = {
            subdistrict: 0,
            extensions: 'all',
            level: 'city'
          };
          //利用行政区查询获取边界构建mask路径
          //也可以直接通过经纬度构建mask路径
          var district = new AMap.DistrictSearch(opts)
          console.log(district)
          district.search('海南省', function (status, result) {

            console.log(status)
            console.log(result)
            var bounds = result.districtList[0].boundaries;
            var mask = []
            for (var i = 0; i < bounds.length; i += 1) {
              mask.push([bounds[i]])
            }
            console.log(mask, 'msks')
            var map = new AMap.Map('container', {
              mask: mask,
              center: [109.839996,19.03557],
              showLabel: true,
              labelzIndex: 130,
              pitch: 40,
              viewMode:'3D',
              zoom: 9,
              // center: [114.49642, 37.0623],
              layers: [
                new AMap.TileLayer.RoadNet({
                  //rejectMapMask:true
                }),
                new AMap.TileLayer.Satellite()
              ]
            });
            var maskerIn = new AMap.Marker({
              position: [116.501415, 39.926055],
              map: map
            })
            var maskerOut = new AMap.Marker({//区域外的不会显示
              position: [117.001415, 39.926055],
              map: map
            })
            console.log(maskerOut, 'maskerOut')
            //添加高度面
          var object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
          map.add(object3Dlayer)
          var height = -8000;
          var color = '#0088ffcc';//rgba
          var wall = new AMap.Object3D.Wall({
            path: bounds,
            height: height,
            color: color
          });
          wall.transparent = true
          object3Dlayer.add(wall)
            //添加描边
            for (var i = 0; i < bounds.length; i += 1) {
              new AMap.Polyline({
                path: bounds[i],
                strokeColor: '#99ffff',
                strokeWeight: 4,
                map: map
              })
            }
          })
        }
      },
      markers: [],
      fildOrdeviceInfo: {
        fieldNum: 0,
        devNum: 0,
        portNum: 0,
        useNum: 0
      },
      incomeInfo: {
        dayIn: 0,
        monthIn: 0,
        dayUserIn: 0,
        monthUserIn: 0
      },
      deviceList: [],
      // zoom: 11,
      // center:[109.839996,19.03557]
    }
  },
  created () {
    // this.getDeviceNum()
    // this.getChartData()
    // this.getMapList()
    // this.init()
  },
  mounted () {
    this.initAMap()
    // this.events = {

    // }
  },
  beforeMount () {
    VueAMap.initAMapApiLoader({
      key: 'b91c333ba27b7299b8fde7440cf22f7c',
      plugin: ['AMap.Autocomplete', 'AMap.Geocoder', 'AMap.PlaceSearch','AMap.Object3DLayer', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'MarkerClusterer', 'AMap.DistrictSearch'],
      v: '1.4.4'
    })
    // VueAMap.initAMapApiLoader({
    //   key: 'b91c333ba27b7299b8fde7440cf22f7c',
    //   plugin: ['AMap.Autocomplete', 'AMap.Geocoder', 'AMap.PlaceSearch','AMap.Object3DLayer', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'MarkerClusterer', 'AMap.DistrictSearch'],
    //   v: '1.4.4'
    // })
    window._AMapSecurityConfig = {
      securityJsCode: '6d2e5df6d96f0efebd8f530ef5e78b02'
    }
  },
  methods: {
    initAMap() {
      console.log(111)
      AMapLoader.load({
        key: "b91c333ba27b7299b8fde7440cf22f7c", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
          'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow', 'AMap.DistrictSearch', 'AMap.Object3DLayer'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        console.log(AMap)
        // 获取到作为地图容器的DOM元素，创建地图实例
        this.map = new AMap.Map("container", { //设置地图容器id
          resizeEnable: true,
          zoom: this.zoom, // 地图显示的缩放级别
          viewMode: "3D", // 使用3D视图
          zoomEnable: true, // 地图是否可缩放，默认值为true
          dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
          doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true 
          center: this.center, // 初始化中心点坐标 西安
        })
 
        var opts = {
          subdistrict: 0,
          extensions: 'all',
          level: 'city'
        }
        // 利用行政区查询获取边界构建mask路径
        // 也可以直接通过经纬度构建mask路径  
        var district = new AMap.DistrictSearch(opts)
        district.search('海南省', function (status, result) {
          // console.log("获取到城市信息", status, result);
          var bounds = result.districtList[0].boundaries;
          // console.log("数据", bounds);
          var mask = []
          for (var i = 0; i < bounds.length; i += 1) {
            mask.push([bounds[i]])
          }
          var map = new AMap.Map('container', {
            mask: mask,
            center: [109.839996,19.03557],
            disableSocket: true,
            viewMode: '3D',
            showLabel: false,
            labelzIndex: 130,
            pitch: 40,
            zoom: 8,
            layers: [
              new AMap.TileLayer.RoadNet({
                //rejectMapMask:true
              }),
              new AMap.TileLayer.Satellite()
            ]
          });
 
          // var maskerIn = new AMap.Marker({
          //   position: [116.501415, 39.926055],
          //   map: map
          // })
          // var maskerOut = new AMap.Marker({//区域外的不会显示
          //   position: [117.001415, 39.926055],
          //   map: map
          // })
 
          //添加高度面
          var object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
          map.add(object3Dlayer)
          var height = -8000;
          var color = '#0088ffcc';//rgba
          var wall = new AMap.Object3D.Wall({
            path: bounds,
            height: height,
            color: color
          });
          wall.transparent = true
          object3Dlayer.add(wall)
          //添加描边
          for (var i = 0; i < bounds.length; i += 1) {
            new AMap.Polyline({
              path: bounds[i],
              strokeColor: '#99ffff',
              strokeWeight: 4,
              map: map
            })
          }
        });
      }).catch(e => {
        console.log(e)
      })
    },

    handleSetLineChartData (type) {
      this.lineChartData = lineChartData[type]
    },
    // 地图数据
    getMapList () {
      qryFieldInfo().then(res => {
        console.log(res, '地图')
        this.deviceList = res
      })
    },
    // 设备数据
    getDeviceNum () {
      getFixationData().then(res => {
        for (const key in this.fildOrdeviceInfo) {
          this.fildOrdeviceInfo[key] = res[key]
        }
        this.lineChartData.timeData = []
        this.lineChartData.totalMoneyData = []
        console.log(this.fildOrdeviceInfo)
        var obj = {
          value: '',
          name: ''
        }
        if (res.fieldDistrs && res.fieldDistrs.length > 0) {
          res.fieldDistrs.forEach(v => {
            this.lineChartData.timeData.push(v.prov)
            obj.value = v.ratioNum
            obj.name = v.prov
            this.lineChartData.totalMoneyData.push(obj)
          })
        }
        console.log(this.lineChartData)
      })
    },
    // 数据表
    getChartData () {
      getChangeData().then(res => {
        console.log(res)
        for (const key in this.incomeInfo) {
          this.incomeInfo[key] = res[key]
        }
        this.lineChartData1.timeData = []
        this.lineChartData1.totalMoneyData = []
        if (res.brokenIns && res.brokenIns.length > 0) {
          res.brokenIns.forEach(v => {
            this.lineChartData1.timeData.push(v.x)
            this.lineChartData1.totalMoneyData.push(v.y)
          })
        }
        this.lineChartData2.timeData = []
        this.lineChartData2.orderNumData = []
        this.lineChartData2.parkOrderNumData = []
        if (res.monthBrokenIns && res.monthBrokenIns.length > 0) {
          res.monthBrokenIns.forEach(v => {
            this.lineChartData2.timeData.push(v.x)
            this.lineChartData2.orderNumData.push(v.y)
            this.lineChartData2.parkOrderNumData.push(v.y2)
          })
        }
      })
    },
    toRoute () {
      console.log(454545)
      this.$router.push({ path: this.$store.getters.defaultRoutePath }).catch(() => { })
    }
  }
}
</script>
<style lang="less" scoped>
.bg {
  background-image: url('./img/2.png');
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .nav {
    width: 100vw;
    height: 9.62vh;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 88vh;
    .left_main {
      width: 26.8vw;
      height: 100%;
      padding: 0 0.78125vw 0 1.04vw;
      .statistics {
        background: url('./img/3.png') no-repeat;
        width: 25vw;
        height: 5vh;
        padding-left: 1.3vw;
        span {
          color: #f8f8f9;
          line-height: 5vh;
          font-size: 0.8vw;
        }
        .tip_en {
          color: rgba(183, 207, 252, 0.4);
          font-weight: bold;
          font-size: 0.5vw;
          margin-right: 0.36vw;
        }
      }
      .fildNum {
        background: url('./img/5.png') no-repeat;
        width: 24vw;
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: 100% 100%;
        padding: 0 0.78vw 0 0.4vw;
        margin-top: 0.78vw;
        .num {
          font-size: 1.4vw;
          font-family: DIN-Bold-, DIN-Bold;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
        .tip {
          font-size: 0.83vw;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
      }
      .statistics_info {
        display: flex;
        align-content: center;
        margin-top: 2vh;
        margin-bottom: 3.3vh;
        .device {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.625vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #edc910;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #edc910;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
        .prot {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          margin-left: 1.8vw;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.625vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #00ff84;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #00ff84;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
      }
      .income {
        margin-top: 4.2vh;
        display: flex;
        align-items: center;
        margin-bottom: 5.3vh;
        .day_income {
          display: flex;
          align-items: flex-start;
          .day_income_icon {
            width: 0.9375vw;
            height: 1.7vh;
          }
          .day_income_info {
            margin-left: 0.46875vw;
            .day_income_info_tip {
              font-size: 0.73vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
            }
            .day_income_num {
              margin-top: 1.4vh;
              background: url('./img/4.png') no-repeat;
              background-size: 100% 100%;
              width: 8.8vw;
              height: 3.5vh;
              font-size: 1vw;
              font-family: PangMenZhengDao-, PangMenZhengDao;
              font-weight: normal;
              color: #43e7ff;
              text-align: center;
              padding: 0.6vh 0 0.9vh;
            }
          }
        }
        .Monthly_income {
          display: flex;
          align-items: flex-start;
          margin-left: 2.6vw;
          .day_income_icon {
            width: 0.9375vw;
            height: 1.7vh;
          }
          .day_income_info {
            margin-left: 0.46875vw;
            .day_income_info_tip {
              font-size: 0.73vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
            }
            .day_income_num {
              margin-top: 1.4vh;
              background: url('./img/4.png') no-repeat;
              background-size: 100% 100%;
              width: 8.8vw;
              height: 3.5vh;
              font-size: 1vw;
              font-family: PangMenZhengDao-, PangMenZhengDao;
              font-weight: normal;
              color: #43e7ff;
              text-align: center;
              padding: 0.6vh 0 0.9vh;
            }
          }
        }
      }
      .line-chart {
      }
    }
    .center_main {
      width: 46.4vw;
      height: 100%;
    }
    .right_main {
      width: 26.8vw;
      height: 100%;
      padding: 0 0.78125vw 0 1.04vw;
      .statistics {
        background: url('./img/3.png') no-repeat;
        width: 25vw;
        height: 5vh;
        padding-left: 1.3vw;
        span {
          color: #f8f8f9;
          line-height: 5vh;
          font-size: 0.8vw;
        }
        .tip_en {
          color: rgba(183, 207, 252, 0.4);
          font-weight: bold;
          font-size: 0.5vw;
          margin-right: 0.36vw;
        }
      }
      .fildNum {
        background: url('./img/5.png') no-repeat;
        width: 24vw;
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-size: 100% 100%;
        padding: 0 0.78vw 0 0.4vw;
        margin-top: 0.78vw;
        .num {
          font-size: 1.4vw;
          font-family: DIN-Bold-, DIN-Bold;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
        .tip {
          font-size: 0.83vw;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #ffffff;
          line-height: 6.5vh;
        }
      }
      .statistics_info {
        display: flex;
        align-content: center;
        margin-top: 2vh;
        margin-bottom: 3.3vh;
        .device {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.625vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #edc910;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #edc910;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
        .prot {
          width: 10.8vw;
          height: 6.7vh;
          display: flex;
          justify-content: space-between;
          padding-right: 0.6vw;
          align-items: center;
          margin-left: 1.8vw;
          background: rgba(194, 250, 255, 0.04);
          .line {
            width: 0.2vw;
            height: 1.7vh;
            background: #00beff;
            border-radius: 0.1vw;
          }
          .device_info {
            .device_info_tip {
              font-size: 0.625vw;
              font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
              font-weight: normal;
              color: #ffffff;
              margin-bottom: 0.9vh;
            }
            .device_info_num {
              font-size: 0.9375vw;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #00ff84;
              font-weight: bold;
              .device_info_num_unit {
                font-size: 0.0625vw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #00ff84;
              }
            }
          }
          .device_bg {
            width: 2.6vw;
            height: 4.63vh;
            background: rgba(27, 150, 255, 0.2);
            display: flex;
            flex-direction: column;
            justify-items: center;
            border-radius: 50%;
            .device_ivon {
              width: 1.1vw;
              height: 2vh;
              margin: auto;
            }
          }
        }
      }
    }
  }
}
#container{
  width: 46.4vw;
      height: 100%;
}
</style>
